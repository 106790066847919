<template>
  <div class="activity-card card table" :class="{ 'is-close': collapse }">
    <header class="card-header">
      <p class="card-header-title">
        <span>Atividades</span>
      </p>
      <div class="card-header-icon" aria-label="collapse card" @click="collapse = !collapse">
        <i class="lni" :class="collapse ? 'lni-chevron-up' : 'lni-chevron-down'" />
      </div>
    </header>
    <div class="card-content">
      <error :error="error" />
      <div class="mb-5">
        <div v-if="!cloneMode" class="is-flex is-justify-content-center">
          <router-link
            class="button is-link"
            :to="`/admin/turmas/${idClass}/atividades/nova`"
          >
            Criar Atividade
          </router-link>
          <div class="divider is-vertical">Ou</div>
          <button class="button is-primary" @click="cloneMode = true">Clonar Atividade</button>
        </div>
        <div v-if="cloneMode" class="clone-mode">
          <div class="field has-addons">
            <div class="control">
              <button class="button is-light" @click="cloneMode = false">
                <i class="lni lni-arrow-left"></i>
              </button>
            </div>
            <div class="control is-expanded">
              <filter-input :list="activities" @select="e => idActivity = e.value" />
            </div>
            <div class="control">
                <button
                  class="button is-primary"
                  :class="{'is-loading': enviando}"
                  @click="clone()"
                  :disabled="enviando"
                >Clonar</button>
            </div>
          </div>
        </div>
      </div>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Título</th>
            <th class="has-text-right">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(activity, index) in classActivities"
            :key="activity.id"
          >
            <td>{{ activity.name }}</td>
            <td class="has-text-right">
              <button class="button is-danger is-small" title="Excluir Turma" @click="excluir(index, activity)">
                <i class="lni lni-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import SwalDelete from '@/helpers/SwalDelete'
import Error from '@/components/ui/Error'
import FilterInput from '@/components/ui/FilterInput'

export default {
  name: 'ActivityCard',
  components: {
    Error,
    FilterInput
  },
  props: {
    idClass: {
      type: Number,
      required: true
    }
  },
  created () {
    api.get(`/classes/${this.idClass}/activities`).then(res => {
      if (res.status === 200) {
        this.classActivities = res.data
      } else {
        this.error = 'Não foi possível carregar as atividades'
      }
    })

    api.get('/activities').then(res => {
      this.activities = res.data
    })
  },
  data () {
    return {
      classActivities: [],
      collapse: false,
      cloneMode: false,
      error: null,
      activities: [],
      idActivity: 0,
      enviando: false
    }
  },
  methods: {
    clone () {
      if (this.idActivity === 0 || this.idActivity === '') {
        this.error = 'Escolha a atividade'
        return
      }
      this.error = null

      api.post(`/classes/${this.idClass}/activity`, {
        idActivity: this.idActivity
      }).then(res => {
        if (res.status === 201) {
          this.error = null
          this.classActivities.push(res.data)
        } else {
          this.error = 'Não foi possível adicionar a atividade'
        }

        this.enviado = false
      }).catch(err => {
        this.enviando = false
        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    excluir (index, activity) {
      SwalDelete.fire().then(result => {
        if (result.isConfirmed) {
          api.delete(`/classes/${this.idClass}/activities/${activity.id}`).then(res => {
            if (res.status === 200) {
              this.classActivities.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &.is-close {
    .card-content {
      max-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}
</style>
