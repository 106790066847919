<template>
  <layout>
    <page-title :icon="`lni lni-${id > 0 ? 'pencil' : 'plus'}`" :breadcrumb="breadcrumb">
      {{ id > 0 ? 'Editar Turma' : 'Nova Turma' }}
    </page-title>
    <div class="card mb-5">
      <div class="card-content">
        <div class="form">
          <div v-if="error" class="notification is-danger field">
            <button class="delete" @click="error = null"></button>
            {{ error }}
          </div>
          <div class="field">
            <label class="label" for="name">Nome<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Nome da turma" ref="name" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="serie">Série<asterisk /></label>
            <div class="select is-fullwidth">
              <select v-model="serie" name="serie" id="serie" ref="serie">
                <option
                  v-for="serieOpt in serieOptions"
                  :key="serieOpt.name"
                  :value="serieOpt.value || serieOpt.name"
                  :selected="serieOpt.value || serieOpt.name === serie"
                >
                  {{ serieOpt.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send">Salvar Turma</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" to="/admin/turmas">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <teacher-card class="mb-5" v-if="id > 0" :idClass="id" />
    <student-card v-if="id > 0" class="mb-5" :idClass="id" />
    <activity-card v-if="id > 0" class="mb-5" :idClass="id" />
  </layout>
</template>

<script>
import api from '@/api'
import SwalLoading from '@/helpers/SwalLoading'
import { DASHBOARD, CLASS_LIST, CLASS_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Asterisk from '@/components/ui/Asterisk'
import TeacherCard from '../teacher/TeacherCard'
import StudentCard from '../student/StudentCard'
import ActivityCard from '../activity/ActivityCard'

export default {
  name: 'ClassForm',
  components: {
    Layout,
    PageTitle,
    Asterisk,
    TeacherCard,
    StudentCard,
    ActivityCard
  },
  created () {
    api.get('/series').then(res => {
      this.series = res.data
    })

    if (this.$route.params.id !== undefined) {
      SwalLoading.fire()

      api.get(`/classes/${this.$route.params.id}`).then(res => {
        if (res.status === 200) {
          this.id = res.data.id
          this.name = res.data.name
          this.serie = res.data.serie.id
        } else {
          this.error = 'Não foi possível carregar os dados'
        }
        SwalLoading.close()
      }).catch(() => {
        this.error = 'Não foi possível carregar os dados'
        SwalLoading.close()
      })
    }
  },
  data () {
    return {
      id: 0,
      name: '',
      serie: '',
      series: [],
      enviando: false,
      error: null
    }
  },
  computed: {
    breadcrumb () {
      const form = { ...CLASS_FORM, isActive: true }
      if (this.id > 0) form.text = 'Editar Turma'

      return [
        DASHBOARD,
        CLASS_LIST,
        form
      ]
    },
    serieOptions () {
      return this.series.map(serie => {
        return {
          value: serie.id,
          name: serie.name
        }
      })
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.name === '') {
        return this.fieldInvalid('name', 'Nome é obrigátorio')
      }
      this.error = null

      if (this.serie === '') {
        return this.fieldInvalid('serie', 'Série é obrigátoria')
      }
      this.error = null

      if (this.id === 0) {
        api.post('/classes', {
          idSerie: this.serie,
          name: this.name
        }).then(res => {
          if (res.status === 201) {
            this.$router.push(`/admin/turmas/${res.data.id}/editar`)
            this.enviando = false
            this.id = res.data.id
            // this.$router.push(`${res.data.id}/editar`)
          } else {
            this.error = 'Turma não foi criada'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/classes/${this.id}`, {
          idSerie: this.serie,
          name: this.name
        }).then(res => {
          if (res.status !== 200) {
            this.error = 'Turma não foi atualizada'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    }
  }
}
</script>
