<template>
  <div class="filter-input">
    <div class="field has-addons">
      <div class="control is-expanded">
        <input v-model="iValue" class="input" type="text" @focus="open = false" />
      </div>
      <div class="control">
        <button class="button is-link" @click="open = true">
          <i class="lni lni-search"></i>
        </button>
      </div>
    </div>
    <aside v-if="open" class="results menu">
      <ul v-if="listFiltered.length > 0" class="menu-list">
        <li
          v-for="item in listFiltered"
          :key="item.id"
          class="result"
        >
          <a @click.prevent="select(item)">{{ item.name }}</a>
        </li>
      </ul>
      <p v-else class="subtitle my-4 has-text-centered">
        <i class="lni lni-information" /> Nenhum registro
      </p>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'FilterInput',
  props: {
    list: {
      type: Array,
      required: true
    },
    value: [Number, String],
    id: String
  },
  data () {
    return {
      iValue: '',
      dataValue: '',
      open: false
    }
  },
  computed: {
    listFiltered () {
      return this.list.filter(item => item.name.includes(this.iValue))
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.dataValue)
    },
    select (item) {
      this.iValue = item.name
      this.dataValue = item.id
      this.$emit('select', { value: this.dataValue })
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-input {
  position: relative;
  .field {
    margin: 0;
  }
  .results {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 99
  }
}
</style>
