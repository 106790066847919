<template>
  <div class="teacher-card card table" :class="{ 'is-close': collapse }">
    <header class="card-header">
      <p class="card-header-title">
        <span>Professores</span>
      </p>
      <div class="card-header-icon" aria-label="collapse card" @click="collapse = !collapse">
        <i class="lni" :class="collapse ? 'lni-chevron-up' : 'lni-chevron-down'" />
      </div>
    </header>
    <div class="card-content">
      <error :error="error" />
      <div class="columns mb-2">
        <div class="column py-0">
          <div class="field">
            <label class="label" for="teacher">Professor<asterisk /></label>
            <filter-input :list="teachers" @select="e => { idTeacher = e.value }" />
          </div>
        </div>
        <div class="column py-0">
          <div class="field">
            <label class="label" for="discipline">Disciplina<asterisk /></label>
            <filter-input v-model="idDiscipline" :list="disciplines" @select="e => { idDiscipline = e.value }" />
          </div>
        </div>
      </div>
      <div class="field">
        <button
          class="button is-primary is-fullwidth"
          :class="{'is-loading': enviando}"
          @click="send"
          :disabled="enviando"
        >
          Adicionar Professor
        </button>
      </div>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Professor</th>
            <th>Disciplina</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(teacher, index) in classTeachers" :key="teacher.id">
            <td>{{ teacher.name }}</td>
            <td>{{ teacher.discipline.name }}</td>
            <td>
              <button class="button is-danger is-small" title="Excluir Turma" @click="excluir(index, teacher)">
                <i class="lni lni-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Swal from 'sweetalert2'
import Error from '@/components/ui/Error'
import Asterisk from '@/components/ui/Asterisk'
import FilterInput from '@/components/ui/FilterInput'

export default {
  name: 'TeacherCard',
  components: {
    Error,
    Asterisk,
    FilterInput
  },
  props: {
    idClass: {
      type: Number,
      required: true
    }
  },
  created () {
    api.get(`/classes/${this.idClass}/teachers`).then(res => {
      if (res.status === 200) {
        this.classTeachers = res.data
      } else {
        this.error = 'Não foi possível carregar os professores'
      }
    }).catch(() => {
      this.error = 'Não foi possível carregar os professores'
    })

    api.get('/teachers').then(res => {
      this.teachers = res.data
    })

    api.get('/disciplines').then(res => {
      this.disciplines = res.data
    })
  },
  data () {
    return {
      idTeacher: '',
      idDiscipline: '',
      classTeachers: [],
      teachers: [],
      disciplines: [],
      collapse: true,
      error: null,
      enviando: false
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.idTeacher === 0 || this.idTeacher === '') {
        this.error = 'Escolha o professor'
        return
      }
      this.error = null

      if (this.idDiscipline === 0 || this.idDiscipline === '') {
        this.error = 'Escolha a disciplina'
        return
      }
      this.error = null

      api.post(`/classes/${this.idClass}/teacher`, {
        idTeacher: this.idTeacher,
        idDiscipline: this.idDiscipline
      }).then(res => {
        if (res.status === 201) {
          this.classTeachers.push(res.data)
        } else {
          this.error = 'Não foi possível adicionar o professor a classe'
        }

        this.enviando = false
      }).catch(err => {
        this.enviando = false
        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    excluir (index, teacher) {
      Swal.fire({
        title: 'Tem certeza?',
        text: `Você tem certeza que deseja excluir o professor: ${teacher.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          api.delete(`/classes/${this.idClass}/teachers/${teacher.id}/disciplines/${teacher.discipline.id}`).then(res => {
            if (res.status === 200) {
              this.classTeachers.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &.is-close {
    .card-content {
      max-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}
</style>
