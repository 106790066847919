<template>
  <div class="student-card card table" :class="{ 'is-close': collapse }">
    <header class="card-header">
      <p class="card-header-title">
        <span>Alunos</span>
      </p>
      <div class="card-header-icon" aria-label="collapse card" @click="collapse = !collapse">
        <i class="lni" :class="collapse ? 'lni-chevron-up' : 'lni-chevron-down'" />
      </div>
    </header>
    <div class="card-content">
      <error :error="error" />
      <div class="field has-addons">
        <div class="control is-expanded">
          <filter-input :list="students" @select="e => { idStudent = e.value }" />
        </div>
        <div class="control">
          <button
            class="button is-primary"
            :class="{'is-loading': enviando}"
            @click="send"
            :disabled="enviando"
          >
            Matricular
          </button>
        </div>
      </div>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Email</th>
            <td>Ações</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(student, index) in classStudents"
            :key="student.id"
          >
            <td>{{ student.name }}</td>
            <td>{{ student.email }}</td>
            <td>
              <button class="button is-danger is-small" title="Excluir Turma" @click="excluir(index, student)">
                <i class="lni lni-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Swal from 'sweetalert2'
import Error from '@/components/ui/Error'
import FilterInput from '@/components/ui/FilterInput'

export default {
  name: 'StudentCard',
  components: {
    Error,
    FilterInput
  },
  props: {
    idClass: {
      type: Number,
      required: true
    }
  },
  created () {
    api.get(`/classes/${this.idClass}/students`).then(res => {
      if (res.status === 200) {
        this.classStudents = res.data
      } else {
        this.error = 'Não foi possível carregar os alunos'
      }
    }).catch(() => {
      this.error = 'Não foi possível carregar os alunos'
    })

    api.get('/students').then(res => {
      this.students = res.data
    })
  },
  data () {
    return {
      idStudent: '',
      students: [],
      classStudents: [],
      collapse: true,
      error: null,
      enviando: false
    }
  },
  methods: {
    send () {
      if (this.idStudent === 0 || this.idStudent === '') {
        this.error = 'Escolha o aluno'
        return
      }
      this.error = null

      api.post(`/classes/${this.idClass}/student`, {
        idStudent: this.idStudent
      }).then(res => {
        if (res.status === 201) {
          this.error = null
          this.classStudents.push(res.data)
        } else {
          this.error = 'Não foi possível adicionar o aluno'
        }

        this.enviando = false
      }).catch(err => {
        this.enviando = false
        if (err.response) {
          this.error = err.response.data.message
        } else {
          this.error = 'Servidor não responde'
        }
      })
    },
    excluir (index, student) {
      Swal.fire({
        title: 'Tem certeza?',
        text: `Você tem certeza que deseja excluir o aluno: ${student.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          api.delete(`/classes/${this.idClass}/students/${student.id}`).then(res => {
            if (res.status === 200) {
              this.classStudents.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &.is-close {
    .card-content {
      max-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}
</style>
